.simple-testimonials {
  margin-bottom: 125px;
  position: relative;
  z-index: 20;

  &__container {

  }
  &__title {
    text-align: center;
    margin-bottom: 23px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: calc(~"(100% - 20px) / 2");
    margin-right: 20px;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 24px 30px 46px 30px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__item-title {
    font-size: 24px;
    color: #1b1923;
    .hammer();
    margin-bottom: 5px;
  }
  &__item-text {
    margin-bottom: 29px;
  }
  &__item-bottom {
    display: flex;
    align-items: center;
  }
  &__item-name {
    color: #1b1923;
    font-size: 16px;
    .opensans-bold();
    margin-right: 16px;
    &:before {
      content: '-';
      color: #1b1923;
      font-size: 16px;
      .opensans-bold();
      display: inline-block;
      margin-right: 5px;
    }
  }
  &__item-logo-wrap {
    width: 77px;
  }
  &__item-logo {
    width: 100%;
  }
}


@media screen and (max-width: 1030px) {
  .simple-testimonials {

    &__list {
      display: block;
    }
    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
