.cta {
	margin-bottom: 160px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: -71px;
		left: 50%;
		margin-left: -1630px;
		width: 2918px;
		height: 975px;
		background: url(../img/cta-bg.png) no-repeat center center;
		background-size: contain;
		z-index: 10;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
	}
	&__title {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: -63px;
			top: -86px;
			width: 183px;
			height: 217px;
			background: url(../img/cta-detail-1.png) no-repeat center center;
			background-size: contain;
			z-index: 10;
		}
	}
	&__form {

	}
	&__main {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 48px;
	}
	&__input-holder {
		width: calc(~"(100% - 16px * 3) / 4");
		margin-right: 16px;
		position: relative;
		z-index: 20;
		&:last-child {
			margin-right: 0;
		}
	}
	&__input {
		width: 100%;
		border-radius: 4px;
		height: 48px;
		line-height: 48px;
		border: 2px solid #d70094;
		background-color: #ffffff;
		padding: 0 15px 0 24px;

		color: #1b1923;
		.hammer();
		font-size: @18;
		-webkit-appearance: none;

		&::-webkit-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:focus {

		}
		&_error {
			border-color: #ff0000;
		}

		.cta__input-holder:last-child & {
			background: transparent;
		}
	}
	&__button-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__button {

	}

	span.wpcf7-not-valid-tip {
		.hammer();
		color: @yellow-color;
		color: @blue-color;
		font-size: 14px;
		margin-top: 2px;
	}

	.wpcf7-response-output {
		padding: 20px;
		border-radius: 4px;
		text-align: center;
		border: none;
	}
	.wpcf7-validation-errors {
		background: @red-color;
		color: white;
	}
	.wpcf7-mail-sent-ok {
		background: @green-color;
		color: white;
	}
}

@media screen and (max-width: 1030px) {
	.cta {
		margin-bottom: 101px;
		/*&:after {
			top: -100px;
		}*/
		&:after {
			top: -40px;
			margin-left: -729px;
			width: 1459px;
			height: 487px;
		}

		&__title-wrap {
			margin-bottom: 25px;
		}
		&__title {
			/*&:after {
				right: -158px;
				top: -116px;
			}*/
			&:after {
				right: -63px;
				top: -22px;
				width: 91px;
				height: 108px;
			}
		}

		&__main {
			margin-bottom: 35px;
		}
	}
}

@media screen and (max-width: 700px) {
	.cta {
		//&:after {
		//	top: -78px;
		//}
		&:after {
			top: -10px;
		}
		&__title {
			text-align: center;
			//&:after {
			//	right: -87px;
			//	top: -89px;
			//}
			&:after {
				right: -43px;
				top: -32px;
			}
		}
		&__main {
			flex-direction: column;
			margin-bottom: 16px;
		}
		&__input-holder {
			width: 100%;
			margin-right: 0;
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__input {
			width: 100%;
			border-radius: 4px;
			height: 48px;
			line-height: 48px;
			border: 2px solid #d70094;
			background-color: #ffffff;
			padding: 0 15px 0 24px;

			color: #1b1923;
			.hammer();
			font-size: @18;

			&::-webkit-input-placeholder {
				color: #1b1923;
				opacity: 1;
			}
			&::-moz-placeholder {
				color: #1b1923;
				opacity: 1;
			}
			&:-moz-placeholder {
				color: #1b1923;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: #1b1923;
				opacity: 1;
			}
			&:focus {

			}
			&_error {
				border-color: #ff0000;
			}
		}
	}
}