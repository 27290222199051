.blog-item {
	margin-right: 16px;
	margin-bottom: 16px;

	.catalog & {
		width: calc(~"(100% - 16px * 2) / 3");
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	.blog & {
		width: calc(~"(100% - 16px) / 2");
		margin-bottom: 35px;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	&__img {
		width: 100%;
		height: 0;
		padding-bottom: 74.0740741%;
		position: relative;
		margin-bottom: 16px;
		display: block;
		border-radius: 4px;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(56, 54, 64, 0.5);
			opacity: 0;
			visibility: hidden;
			.std-transition();
		}
		.no-touchevents & {
			&:hover {
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&__img-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	&__text-wrap {
		padding-left: 12px;
		padding-right: 2px;
	}
	&__title-wrap {
		margin-bottom: 2px;
	}
	&__title {
		color: #1b1923;
		font-size: @24;
		.hammer();
		text-decoration: none;
		line-height: 1.25;
		.std-transition();
		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__text {
		color: #1b1923;
		font-size: @16;
		line-height: 1.5;
		.opensans();
		margin-bottom: 12px;
	}
	&__info {
		color: rgba(27, 25, 35, 0.5);
		font-size: @14;
		.opensans();
	}
}

@media screen and (max-width: 1030px) {
	.blog-item {
		margin-right: 14px;
		margin-bottom: 16px;

		.catalog & {
			width: calc(~"(100% - 14px) / 2");
			&:nth-child(3n) {
				margin-right: 12px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:nth-child(2) {
				&:after {
					content: '';
					position: absolute;
					top: -19px;
					right: -38px;
					width: 242px;
					height: 250px;
					background: url(../img/yellow-detail-3.png) no-repeat center center;
					background-size: contain;
					z-index: 10;
				}
			}
		}
		.catalog_blog & {
			&:nth-child(3) {
				display: none;
			}
		}

		&__text-wrap {
			padding-left: 0;
			padding-right: 15px;
		}
	}
}

@media screen and (max-width: 700px) {
	.blog-item {
		margin-right: 0;
		margin-bottom: 35px;

		.catalog & {
			width: 290px;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(2) {
				&:after {
					display: none;
				}
			}
		}
		.blog & {
			width: calc(~"(100% - 16px) / 2");
			margin-right: 16px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}

		&__img {
			height: 224px;
			padding-bottom: 0;
		}
	}
}

@media screen and (max-width: 500px) {
	.blog-item {
		.blog & {
			width: 290px;
			margin-right: 0;
		}
	}
}