.top-slider {
	margin-bottom: 48px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		background: url(../img/top-slider-bg-1.png) no-repeat center center;
		background-size: contain;
		width: 1731px;
		height: 672px;
		left: -201px;
		top: -69px;
		z-index: 10;
	}

	&__itself {
		position: relative;
		z-index: 20;
	}

	&__item {
		height: 0;
		width: 100%;
		padding-bottom: 54.7945205%;
		position: relative;
		z-index: 20;
		border-radius: 4px;
	}
	&__item-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 4px;

		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&__arrow {
		position: absolute;
		top: 50%;
		.svg-icon(icon-slider-arrow, 24px, 42px);
		z-index: 30;

		&_prev {
			left: 29px;
		}
		&_next {
			right: 29px;
			transform: rotate(180deg);
		}
		.no-touchevents & {
			&:hover {
				.svg-icon-param(icon-slider-arrow, 24px, 42px, '[fill]:  rgba(255, 255, 255, 0.5)');
			}
		}
	}
	&__pagination {
		display: none;
		bottom: 16px !important;
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: white;
			opacity: 1;
		}
		.swiper-pagination-bullet-active {
			background: rgba(255, 255, 255, 0.5);
			opacity: 1;
		}
	}
}

@media screen and (max-width: 1030px) {
	.top-slider {
		margin-bottom: 23px;
		&:after {
			width: 865px;
			height: 336px;
			left: 0;
			top: 140px;
		}

		&__item {
			height: 404px;
			padding-bottom: 0;
		}
		&__arrow {
			&_prev {
				left: 16px;
			}
			&_next {
				right: 16px;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.top-slider {
		margin-bottom: 30px;

		&__item {
			height: 320px;
		}
		&__arrow {
			display: none;
		}
		&__pagination {
			display: block;
		}
	}
}