.contacts-form {
	margin-bottom: 161px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 50px;
		left: 50%;
		margin-left: -1630px;
		width: 2918px;
		height: 975px;
		background: url(../img/cta-bg.png) no-repeat center center;
		background-size: contain;
		z-index: 10;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}
	&__title {
		position: relative;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			left: -155px;
			top: -49px;
			width: 97px;
			height: 136px;
			background: url(../img/pink-detail-1.png) no-repeat center center;
			background-size: contain;
			z-index: 30;
		}
	}

	&__form {
		width: 576px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: -50px;
			top: -34px;
			width: 105px;
			height: 63px;
			background: url(../img/pink-detail-2.png) no-repeat center center;
			background-size: contain;
			z-index: 30;
		}
	}
}

@media screen and (max-width: 1030px) {
	.contacts-form {
		&:after {
			top: 20px;
			margin-left: -1657px;
		}

		&__title {
			&:after {
				left: auto;
				right: -70px;
				top: -97px;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.contacts-form {

		&__title {
			&:after {
				right: -83px;
				top: -40px;
			}
		}
		&__form {
			width: 100%;
		}
	}
}