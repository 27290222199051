.arcticmodal-overlay,
.arcticmodal-container {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
}

.arcticmodal-container {
	overflow: hidden;
	margin: 0;
	padding: 0;
	border: 0;
	border-collapse: collapse;
}

*:first-child + html .arcticmodal-container {
	height: 100%
}

.arcticmodal-container_i {
	height: 100%;
	margin: 0 auto;
}

.arcticmodal-container_i2 {
	padding: 0;
	margin: 0;
	border: 0;
	vertical-align: middle;
}

.arcticmodal-error {
	padding: 20px;
	border-radius: 10px;
	background: #000;
	color: #fff;
}

.arcticmodal-loading {
	width: 80px;
	height: 80px;
	border-radius: 10px;
	background: #000 url(../bower_components/arcticmodalbw/arcticmodal/loading.gif) no-repeat 50% 50%;
}


.box-modal {
	position: relative;
	//width: 289px;
	padding: 0;
	//background: white;
	font-size: 16px;
	box-sizing: border-box;
	animation-duration: 800ms;
	-webkit-animation-duration: 800ms;

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		.svg-icon(icon-popup-close, 30px, 30px);
		cursor: pointer;
		z-index: 30;
		.no-touchevents & {
			&:hover {
				.svg-icon-param(icon-popup-close, 30px, 30px, '[fill]: #d70094');
			}
		}
	}

	&__content {

	}

}

@media screen and (max-width: 700px) {
	.box-modal {

		&__close {
			top: -50px;
		}
	}
}

@media screen and (max-width: 420px) {
	.box-modal {
		animation-duration: 0ms;
		-webkit-animation-duration: 0ms;
	}
	.arcticmodal-container_i2 {
		padding: 0;
		margin: 0;
		border: 0;
		vertical-align: middle;
	}
}