.mobile-menu {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(153, 198, 0, 0.95);
	padding-top: 83px;
	display: none;
	z-index: 100;

	&_pink {
		background: rgba(215, 0, 148, 0.95);
	}
	&_red {
		background: rgba(234, 18, 18, 0.95);
	}
	&_yellow {
		background: rgba(251, 180, 1, 0.95);
	}
	&_blue {
		background: rgba(55, 205, 252, 0.95);
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&__item {
		margin-bottom: 17px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__item-link {
		color: #fefefe;
		font-size: @24;
		.hammer();
		text-decoration: none;
	}
	&__close {
		.svg-icon(icon-popup-close, 20px, 20px);
		position: absolute;
		top: 25px;
		right: 29px;
		text-decoration: none;
	}
}