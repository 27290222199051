.footer {
	position: relative;
	min-height: 500px;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -920px;
		width: 1841px;
		height: 729px;
		background: url(../img/footer-bg.png) no-repeat center bottom;
		background-size: contain;
		z-index: 10;
	}

	&__container {

	}
	&__wrap {
		border-radius: 16px 16px 0 0;
		background: @grey-color;
		width: 100%;
		padding: 51px 48px 37px 48px;
		position: relative;
		z-index: 20;
		min-height: 500px;
	}

	&__top {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 51px;
		position: relative;
		z-index: 20;
		min-height:  298px;
	}
	&__contacts {
		width: 20%;
		min-width: 20%;
		margin-right: 85px;
	}
	&__contacts-block {
		&:first-child {
			margin-bottom: 46px;
		}
	}
	&__contacts-text {
		color: #787581;
		font-size: @14;
		.opensans();
		margin-bottom: 13px;
	}
	&__phone {
		color: #ffffff;
		font-size: @24;
		.hammer();
		a {
			text-decoration: none;
			color: #ffffff;
		}
	}
	&__address {
		color: #ffffff;
		font-size: @24;
		.hammer();
		line-height: 1.25;
		a {
			text-decoration: none;
			color: #ffffff;
		}
	}

	&__menu-block {
		width: calc(~"100% - 20% - 85px");
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__menu-column {
		&:first-child {
			width: calc(~"100% - 20%");
		}
		&:last-child {
			width: 25%;
			min-width: 25%;
		}
	}
	&__menu-title {
		color: #ffffff;
		font-size: @18;
		.hammer();
		margin-bottom: 17px;
	}
	&__menu-title-link {
		color: white;
		font-size: @18;
		.hammer();
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&__menu-wrap {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

	}
	&__menu-column-top {
		margin-bottom: 46px;
	}
	&__menu {
		&:first-child {
			margin-right: 35px;
		}
		&:only-child {
			margin-right: 0;
		}
	}
	&__menu-item {
		margin-bottom: 19px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__menu-link {
		color: #787581;
		font-size: @16;
		.opensans();
		text-decoration: none;
		.std-transition();

		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__bottom {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-top: 2px solid #282631;
		padding-top: 37px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			left: 21px;
			bottom: -37px;
			background: url(../img/footer-detail.png) no-repeat 0 0;
			background-size: contain;
			width: 394px;
			height: 420px;
			z-index: 10;
		}
	}
	&__copyright {
		color: #787581;
		font-size: @14;
		.opensans();
	}
	&__bottom-right-part {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__development {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: none;
		margin-right: 28px;
	}
	&__development-text {
		color: #787581;
		.opensans();
		font-size: @14;
		margin-right: 5px;
	}
	&__development-icon {
		background: url(../img/logo-hype-creations.png) no-repeat 0 0;
		background-size: contain;
		width: 24px;
		height: 24px;
	}
	&__serv-block {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__serv-link {
		color: #787581;
		.opensans();
		font-size: @14;
		text-decoration: none;
		.std-transition();
		&:hover {
			text-decoration: underline;
		}
		&:first-child {
			margin-right: 28px;
		}
	}
}

@media screen and (max-width: 1030px) {
	.footer {

		&__wrap {
			border-radius: 0;
			padding: 54px 15px 37px 15px;
		}

		&__top {
			margin-bottom: 46px;
		}
		&__contacts {
			width: 30%;
			min-width: 30%;
			margin-right: 40px;
		}

		&__menu-block {
			width: calc(~"100% - 30% - 40px");
		}

		&__menu-column {
			&:first-child {
				width: 50%;
			}
			&:last-child {
				width: 50%;
				min-width: 50%;
			}
		}

		&__menu-wrap {
			flex-direction: column;
		}

		//&__menu-column-top {
		//	margin-bottom: 46px;
		//}
		//&__menu {
		//	&:first-child {
		//		margin-right: 35px;
		//	}
		//	&:only-child {
		//		margin-right: 0;
		//	}
		//}
		//&__menu-item {
		//	margin-bottom: 19px;
		//	&:last-child {
		//		margin-bottom: 0;
		//	}
		//}
		//&__menu-link {
		//	color: #787581;
		//	font-size: @16;
		//	.opensans();
		//	text-decoration: none;
		//	.std-transition();
		//}

		&__bottom {
		/*	&:after {
				left: auto;
				right: -156px;
				bottom: -67px;
			}*/
			&:after {
				left: auto;
				right: -50px;
				bottom: 53px;
				width: 192px;
				height: 210px;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.footer {
		min-height: 0;
		&:after {
			bottom: 103px;
			left: auto;
			margin-left: 0;
			right: -498px;
			width: 936px;
			height: 453px;
			background: url(../img/footer-bg-2.png) no-repeat center bottom;
			background-size: contain;
		}
		&__wrap {
			padding: 54px 0 37px 0;
			min-height: 0;
		}
		&__contacts {
			width: 100%;
			min-width: auto;
			margin-right: 0;
			padding-left: 40px;
			padding-right: 15px;
		}
		&__contacts-block {
			margin-bottom: 35px;
		}
		&__menu-block {
			display: none;
		}

		&__top {
			min-height: 0;
		}

		&__bottom {
			flex-direction: column;
			/*&:after {
				bottom: 15px;
				right: -116px;
				background: url(../img/footer-detail-2.png) no-repeat 0 0;
				background-size: contain;
				width: 321px;
				height: 217px;
			}*/
			&:after {
				bottom: 85px;
				right: -36px;
				background: url(../img/footer-detail-2.png) no-repeat 0 0;
				background-size: contain;
				width: 160px;
				height: 109px;
			}
		}
		&__copyright {
			margin-bottom: 15px;
		}
		&__bottom-right-part {
			flex-direction: column;
			position: relative;
			z-index: 30;
		}
		&__development {
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
}