.info-box {
	font-size: @16;
	color: #ffffff;
	line-height: 1.4;

	.text-block & {
		line-height: 1.5;
	}
	.article & {
		color: #2d1f42;
		line-height: 1.5;
	}
	.blog & {
		color: #2d1f42;
		line-height: 1.5;
		.opensans();
	}
	&_on-white {
		color: #1b1923;
		line-height: 1.5;
		.opensans();
	}

	.testimonials & {
		font-style: italic;
		.opensans();
		color: #2d1f42;
	}

	.simple-testimonials & {
		font-style: italic;
		.opensans();
		color: #2d1f42;
	}

	h2 {
		.hammer();
		font-size: @36;
		margin-bottom: 26px;
	}

	h3 {
		.hammer();
		font-size: @20;
		margin-bottom: 26px;
	}

	p {
		margin-bottom: 18px;
		&:last-child {
			margin-bottom: 0;
		}

		.text-block & {
			margin-bottom: 25px;
		}
		.article & {
			margin-bottom: 25px;
		}
		.blog & {
			margin-bottom: 25px;
		}
		.info-box_on-white & {
			margin-bottom: 20px;
		}
	}

	img {
		max-width: 100%;
		margin-bottom: 30px;
	}

	a {
		color: #ff1e1e;
	}

	ul, ol {
		margin-left: 25px;
	}
	ul {
		li {
			position: relative;
			margin-bottom: 10px;
			&:before {
				content: "";
				left: -25px;
				top: 50%;
				margin-top: -3px;
				position: absolute;
				background: #ff1e1e;
				height: 6px;
				width: 6px;
				border-radius: 50%;
			}
		}
	}
}
