.std-page {
	margin-top: 160px;

	&__container {

	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 27px;
	}
	&__title {
		position: relative;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			background: url(../img/top-slider-detail-2.png) no-repeat 0 0;
			background-size: contain;
			width: 129px;
			height: 111px;
			top: 25px;
			right: -16px - 129px;
			z-index: 40;
		}
		&:before {
			content: '';
			position: absolute;
			background: url(../img/top-slider-detail-1.png) no-repeat 0 0;
			background-size: contain;
			width: 80px;
			height: 95px;
			top: -55px;
			left: -55px - 80px;
			z-index: 40;
		}
		&_no-bg {
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.std-page {
		margin-top: 100px;

		&__title {
			&:after {
				width: 64px;
				height: 55px;
				top: 35px;
				right: -85px;
			}
			&:before {
				width: 40px;
				height: 47px;
				top: -35px;
				left: -45px;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.std-page {
		margin-top: 40px;

		&__title {
			text-align: center;
		}
	}
}