@import url('https://fonts.googleapis.com/css?family=Hammersmith+One|Lato:900|Open+Sans:400,700&display=swap');

.opensans() {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.opensans-bold() {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
}

.hammer() {
	font-family: 'Hammersmith One', sans-serif;
	font-weight: 400;
}

.lato() {
	font-family: 'Lato', sans-serif;
	font-weight: 900;
}


@1:0.0625rem; @2:0.125rem; @3:0.1875rem; @4:0.25rem; @5:0.3125rem; @6:0.375rem; @7:0.4375rem; @8:0.5rem; @9:0.5625rem; @10:0.625rem; @11:0.6875rem; @12:0.75rem; @13:0.8125rem; @14:0.875rem; @15:0.9375rem; @16:1rem; @17:1.0625rem; @18:1.125rem; @19:1.1875rem; @20:1.25rem; @21:1.3125rem; @22:1.375rem; @23:1.4375rem; @24:1.5rem; @25:1.5625rem; @26:1.625rem; @27:1.6875rem; @28:1.75rem; @29:1.8125rem; @30:1.875rem; @31:1.9375rem; @32:2rem; @33:2.0625rem; @34:2.125rem; @35:2.1875rem; @36:2.25rem; @37:2.3125rem; @38:2.375rem; @39:2.4375rem; @40:2.5rem; @41:2.5625rem; @42:2.625rem; @43:2.6875rem; @44:2.75rem; @45:2.8125rem; @46:2.875rem; @47:2.9375rem; @48:3rem; @49:3.0625rem; @50:3.125rem; @51:3.1875rem; @52:3.25rem; @53:3.3125rem; @54:3.375rem; @55:3.4375rem; @56:3.5rem; @57:3.5625rem; @58:3.625rem; @59:3.6875rem; @60:3.75rem; @61:3.8125rem; @62:3.875rem; @63:3.3975rem; @64:4rem; @65:4.0625rem; @66:4.125rem; @67:4.1875rem; @68:4.25rem; @69:4.3125rem; @70:4.375rem; @71:4.4375rem; @72:4.5rem; @73:4.5625rem; @74:4.625rem; @75:4.6875rem; @76:4.75rem; @77:4.8125rem; @78:4.875rem; @79:4.9375rem; @80:5rem; @81:5.0625rem; @82:5.125rem; @83:5.1875rem; @84:5.25rem; @85:5.3125rem; @86:5.375rem; @87:5.4375rem; @88:5.5rem; @89:5.5625rem; @90:5.625rem; @91:5.6875rem; @92:5.625rem; @93:5.8125rem; @94:5.875rem; @95:5.9375rem; @96:6rem; @97:6.0625rem; @98:6.125rem; @99:6.1875rem; @100:6.25rem; @101:6.3125rem; @102:6.375rem; @103:6.4375rem; @104:6.5rem; @105:6.5625rem; @106:6.625rem; @107:6.6875rem; @108:6.75rem; @109:6.8125rem; @110:6.875rem;
