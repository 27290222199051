

@green-color: #99c600;
@blue-color: #37cdfc;
@grey-color: #2e2c37;
@red-color: #e80000;
@yellow-color: #f2c900;
@pink-color: #db0096;

.svg-icon(@icon, @width, @height) {
	background: svg('@{icon}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}

.svg-icon-param(@icon, @width, @height, @param) {
	background: svg('@{icon}', '@{param}') no-repeat 0 0;
	background-size: @width @height;
	width: @width;
	height: @height;
}

.std-transition() {
	transition: all .3s ease;
}

#eapps-instagram-feed-1 .eapps-instagram-feed-posts-item {
	border-radius: 16px;
}
