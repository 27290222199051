.main-slider {
	margin-bottom: 68px;
	position: relative;
	z-index: 10;
	margin-top: -25px;

	&__container {
		position: relative;
	}
	&__item {
		width: 100%;
		//height: 0;
		//padding-bottom: 33.3333%;
		height: 640px;
	}
	&__item-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	&__item-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: flex-end;
		&_response {
			display: none;
			position: relative;
			width: 100%;
			background: @grey-color;
			padding-top: 36px;
			padding-bottom: 40px;
		}
	}
	&__item-text-wrap {
		width: 25%;
		margin-right: 140px;
	}
	&__item-title {
		color: #ffffff;
		font-size: @72;
		.hammer();
		margin-bottom: 7px;
	}
	&__item-desc {
		color: #ffffff;
		font-size: @16;
		line-height: 1.5;
		.opensans();
		margin-bottom: 20px;
	}

	&__arrow-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__arrow {
		position: absolute;
		top: 50%;
		.svg-icon(icon-slider-arrow, 24px, 42px);
		z-index: 30;

		&_prev {
			left: 29px;
		}
		&_next {
			right: 29px;
			transform: rotate(180deg);
		}
		.no-touchevents & {
			&:hover {
				.svg-icon-param(icon-slider-arrow, 24px, 42px, '[fill]:  rgba(255, 255, 255, 0.5)');
			}
		}
	}

	&__pagination {
		display: none;
		top: 353px !important;
		bottom: auto !important;
		position: absolute;
		text-align: center;
		z-index: 50;
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: white;
			opacity: 1;
		}
		.swiper-pagination-bullet-active {
			background: rgba(255, 255, 255, 0.5);
			opacity: 1;
		}
	}
}

@media screen and (max-width: 1090px) {
	.main-slider {
		margin-top: -40px;
	}
}

@media screen and (max-width: 940px) {
	.main-slider {
		margin-top: 19px;
	}
}

@media screen and (max-width: 1030px) {
	.main-slider {

		&__item {
			height: 384px;
			padding-bottom: 0;
		}
		&__item-text-wrap {
			width: 250px;
			margin-right: 90px;
		}
		&__item-title {
			font-size: @48;
			margin-bottom: 12px;
		}
		&__arrow {
			&_prev {
				left: 15px;
			}
			&_next {
				right: 15px;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.main-slider {

		&__item {
			height: auto;
		}
		&__item-inner {
			height: 384px;
			position: relative;
			width: 100%;
		}
		&__item-container {
			display: none;
			&_response {
				display: flex;
			}
		}
		&__item-text-wrap {
			width: 100%;
			margin-right: 0;
		}
		&__arrow-container {
			display: none;
		}
		&__pagination {
			display: block;
		}
	}
}
