.catalog {
	position: relative;
	margin-bottom: 155px;
	&:after {
		content: '';
		position: absolute;
		z-index: 10;
	}
	&_works {
		&:after {
			top: -99px;
			left: 50%;
			margin-left: -1354px;
			width: 2550px;
			height: 441px;
			background: url(../img/works-bg.png) no-repeat center bottom;
			background-size: contain;
		}
	}
	&_blog {
		&:after {
			top: -297px;
			left: 50%;
			margin-left: -1003px;
			width: 2180px;
			height: 984px;
			background: url(../img/our-blog-bg.png) no-repeat center center;
			background-size: contain;
		}
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		margin-bottom: 30px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	&__title {
		position: relative;
		.catalog_works & {
			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 50%;
				margin-left: -377px;
				width: 770px;
				height: 171px;
				background: url(../img/works-detail-2.png) no-repeat 0 0;
				background-size: contain;
				z-index: 30;
			}
		}
		.catalog_blog & {
			&:after {
				content: '';
				position: absolute;
				top: -80px;
				left: 50%;
				margin-left: -223px;
				width: 528px;
				height: 251px;
				background: url(../img/our-blog-detail.png) no-repeat 0 0;
				background-size: contain;
				z-index: 30;
			}
		}

	}
	&__list {
		margin-bottom: 42px;

		.alm-reveal {
			display: flex;
		}
	}
	&__item {
		width: calc(~"(100% - 16px * 2) / 3");
		margin-right: 16px;
		&:last-child {
			margin-right: 0;
			position: relative;
			.catalog_works & {
				&:after {
					content: '';
					position: absolute;
					top: 122px;
					right: -128px;
					width: 183px;
					height: 217px;
					background: url(../img/works-detail-1.png) no-repeat 0 0;
					background-size: contain;
					z-index: 30;
				}
			}
		}
	}

	&__button-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 1030px) {
	.catalog {

		&_works {
			margin-bottom: 115px;
			&:after {
				top: -54px;
			}
		}
		&_blog {
			&:after {
				top: -159px;
				margin-left: -1031px;
			}
		}

		&__title-wrap {
			.catalog_blog & {
				margin-bottom: 27px;
			}
		}
		&__title {
			.catalog_works & {
				&:after {
					bottom: auto;
					top: -60px;
					right: 53px;
					left: auto;
					margin-left: 0;
					width: 289px;
					height: 96px;
					background: url(../img/red-detail-1.png) no-repeat 0 0;
					background-size: contain;
					transform: rotate(-10deg);
				}
			}
			.catalog_blog & {
				&:after {
					top: -51px;
					left: -63px;
					margin-left: 0;
					width: 195px;
					height: 146px;
					background: url(../img/yellow-detail-2.png) no-repeat 0 0;
					background-size: contain;
				}
			}
		}
		&__list {
			flex-wrap: wrap;
			.alm-reveal {
				flex-wrap: wrap;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.catalog {
		&_works {
			margin-bottom: 104px;
		}

		&__title {
			.catalog_works & {
				&:after {
					top: -54px;
					right: -27px;
				}
			}
		}
		&__list {
			.alm-reveal {
				flex-wrap: nowrap;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}
}