.contact-us {
	position: relative;
	width: 576px;
	&:after {
		content: '';
		position: absolute;
		top: 16px;
		left: 50%;
		margin-left: -1630px;
		width: 2918px;
		height: 975px;
		background: url(../img/cta-bg.png) no-repeat center center;
		background-size: contain;
		z-index: 10;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 35px;
	}
	&__title {
		color: #ffffff;
		font-size: @48;
		.hammer();
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: -101px;
			right: -95px;
			background: url(../img/pink-detail-3.png) no-repeat center center;
			background-size: contain;
			width: 95px;
			height: 136px;
			z-index: 30;
		}
	}

	&__form {
		width: 100%;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: -14px;
			top: -11px;
			width: 105px;
			height: 63px;
			background: url(../img/pink-detail-2.png) no-repeat center center;
			background-size: contain;
			z-index: 30;
		}
	}
}

@media screen and (max-width: 700px) {
	.contact-us {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}