.form {

	&__input-holder {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 18px;
		.form__input-holder & {
			margin-right: 16px;
			width: calc(~"(100% - 16px) / 2");
			margin-bottom: 0;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__input {
		height: 48px;
		line-height: 48px;
		width: 100%;
		padding: 0 22px;
		border: 2px solid #d70094;
		border-radius: 4px;
		background: #ffffff;
		-webkit-appearance: none;

		outline: none;
		color: #1b1923;
		font-size: @18;
		.hammer();

		&::-webkit-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:focus {
			border-color: @blue-color;
		}
		&_error {
			border-color: #ff0000;
		}

		.comments__form & {
			border: 2px solid #ebebeb;
			&:focus {
				border-color: @green-color;
			}
		}
		.get-a-quote & {
			border: 2px solid #8fbe05;
			&:focus {
				border-color: #d70094;
			}
		}
	}

	&__textarea-holder {
		width: 100%;
		margin-bottom: 48px;
		position: relative;
		.comments__form & {
			&:after {
				content: '';
				position: absolute;
				left: 156px;
				bottom: -60px;
				width: 82px;
				height: 75px;
				background: url(../img/green-detail-1.png) no-repeat center center;
				background-size: contain;
				z-index: 30;
			}
		}
		.get-a-quote & {
			margin-bottom: 25px;
		}
	}
	&__textarea {
		height: 96px;
		line-height: 1.67;
		width: 100%;
		padding: 16px 22px;
		border: 2px solid #d70094;
		border-radius: 4px;
		background: #ffffff;
		-webkit-appearance: none;

		outline: none;
		color: #1b1923;
		font-size: @18;
		.hammer();

		&::-webkit-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:focus {
			border-color: @blue-color;
		}
		&_error {
			border-color: #ff0000;
		}

		.comments__form & {
			border: 2px solid #ebebeb;
			height: 124px;
			&:focus {
				border-color: @green-color;
			}
		}
		.get-a-quote & {
			border: 2px solid #8fbe05;
			&:focus {
				border-color: #d70094;
			}
		}
	}

	&__select-holder {
		margin-bottom: 25px;
	}
	&__select-wrap {
		border: 2px solid #d70094;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 26px;
			top: 50%;
			.svg-icon(icon-caret, 10px, 6px);
			margin-top: -3px;
		}
		.get-a-quote & {
			border: 2px solid #8fbe05;
			&:focus {
				border-color: #d70094;
			}
		}
	}
	&__select {
		height: 48px;
		line-height: 48px;
		width: 110%;
		padding: 0 22px;


		background: #ffffff;
		-webkit-appearance: none;

		outline: none;
		color: #1b1923;
		font-size: @18;
		.hammer();

		&::-webkit-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
	}

	&__block {
		margin-bottom: 25px;
	}
	&__block-title {
		color: #ffffff;
		font-size: 18px;
		.hammer();
		margin-bottom: 9px;
	}
	&__block-list {
		display: flex;
	}
	&__block-item {
		width: calc(~"(100% - 25px) / 2");
		margin-right: 25px;
		position: relative;
		&:nth-child(2n) {
			margin-right: 0;
		}
		.form__block-list_photos & {
			height: 100px;
		}
		&_no-photo {
			background: white;
			border: 2px solid #8fbe05;
			border-radius: 4px;
		}
		&_upload {
			border-radius: 4px;
		}
		p {
			position: absolute;
			right: -3px;
			top: -3px;
			width: 22px;
			height: 22px;
		}
	}
	&__block-item-inner {
		width: 100%;
		height: 100px;
		position: relative;
	}
	&__block-item-img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		border-radius: 4px;

		.form__block-item_no-photo & {
			display: flex;
			justify-content: center;
			align-items: center;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				.svg-icon(icon-photo, 40px, 40px);
				margin-top: -20px;
				margin-left: -20px;
				pointer-events: none;
			}
		}
		.wpcf7-form-control-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
		}
	}

	&__block-item-delete {
		text-decoration: none;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background: white;

		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
		visibility: hidden;
		z-index: 50;
		padding: 0;
		border: none;
		box-shadow: none;
		-webkit-appearance: none;
		cursor: pointer;
		outline: none;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -6px;
			margin-left: -6px;
			.svg-icon-param(icon-delete-photo, 12px, 12px, '[fill]: #222222');
		}
		.form__block-item_upload & {
			opacity: 1;
			visibility: visible;
		}
		&:hover {
			&:after {
				.svg-icon-param(icon-delete-photo, 12px, 12px, '[fill]: #8fbe05');
			}
		}
	}
	&__upload-photo {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		background: white;
		border: 0;
		outline: none;
		-webkit-appearance: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		cursor: pointer;
		z-index: 40;
		.form__block-item_upload & {
			display: none;
		}
	}
	&__upload-input {
		width: 100%;
		height: 100%;
		//visibility: hidden;
		opacity: 0;
		z-index: 50;
	}

	&__button-holder {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	span.wpcf7-not-valid-tip {
		.hammer();
		color: @yellow-color;
		color: @blue-color;
		font-size: 14px;
		margin-top: 2px;
	}

	.wpcf7-response-output {
		padding: 20px;
		border-radius: 4px;
		text-align: center;
		border: none;
	}
	.wpcf7-validation-errors {
		background: @red-color;
		color: white;
	}
	.wpcf7-mail-sent-ok {
		background: @green-color;
		color: white;
	}
	.wpcf7-form-control-wrap {
		width: 100%;
	}
}

@media screen and (max-width: 1030px) {
	.form {

		&__input-holder {
			margin-bottom: 14px;
		}
		&__input {
			padding: 0 16px;
		}

		&__textarea-holder {
			margin-bottom: 20px;
			/*.comments__form & {
				&:after {
					left: 21px;
					bottom: -55px;
				}
			}*/
			.comments__form & {
				&:after {
					left: 16px;
					bottom: -20px;
					width: 41px;
					height: 37px;
				}
			}
		}
		&__textarea {
			padding: 6px 16px;
		}
	}
}

@media screen and (max-width: 700px) {
	.form {

		&__input-holder {
			flex-direction: column;
			margin-bottom: 0;
			.form__input-holder & {
				margin-right: 0;
				width: 100%;
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 16px;
				}
			}
			.get-a-quote & {
				margin-bottom: 25px;
			}
		}
		&__textarea-holder {
			margin-bottom: 16px;
		}
	}
}
