.heading-72 {
	color: #1b1923;
	font-size: @72;
	.hammer();
}

@media screen and (max-width: 1030px) {
	.heading-72 {
		font-size: @48;
	}
}

@media screen and (max-width: 700px) {
	.heading-72 {
		font-size: @36;
	}
}