.signs-item {
	border-radius: 16px;
	height: 280px;
	background: @grey-color;
	text-decoration: none;
	width: calc(~"(100% - 16px * 3) / 4");
	margin-right: 16px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:nth-child(4n) {
		margin-right: 0;
	}

	.signs & {
		&:nth-child(5) {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: url(../img/signs-detail-2.png) no-repeat 0 0;
				background-size: contain;
				width: 219px;
				height: 167px;
				bottom: -78px;
				left: -116px;
				z-index: 40;
			}
		}
		&:nth-child(16) {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: url(../img/signs-detail-3.png) no-repeat 0 0;
				background-size: contain;
				width: 183px;
				height: 217px;
				bottom: -84px;
				right: -89px;
				z-index: 40;
			}
		}
	}
	.other-products & {
		&:nth-child(4) {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: url(../img/other-products-detail.png) no-repeat 0 0;
				background-size: contain;
				width: 129px;
				height: 111px;
				bottom: 56px;
				right: -89px;
				z-index: 40;
			}
		}
	}
	.no-touchevents & {
		&:hover {
			background-color: #383640;
		}
	}

	&__inner {
		width: 80%;
	}
	&__icon {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 106px;
		margin-bottom: 23px;
		position: relative;
		margin-left: auto;
		margin-right: auto;

	}
	&__name {
		color: #ffffff;
		font-size: @24;
		line-height: 1.25;
		.hammer();
		.std-transition();
		text-align: center;
		.signs-item:hover & {
			.no-touchevents & {
				text-decoration: underline;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.signs-item {
		width: calc(~"(100% - 16px * 2) / 3");

		&:nth-child(4n) {
			margin-right: 16px;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		.other-products & {
			width: calc(~"(100% - 16px) / 2");
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:nth-child(3n) {
				margin-right: 16px;
			}
			&:nth-child(4) {
				&:after {
					width: 64px;
					height: 55px;
					bottom: -14px;
					right: -19px;
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.signs-item {
		width: 290px;
		margin-right: 0;

		&:nth-child(4n) {
			margin-right: 0;
		}

		.other-products & {
			width: 290px;
		}
	}
}
