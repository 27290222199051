.text-block {
	margin-bottom: 150px;
	position: relative;
	z-index: 20;
	display: flex;
	align-items: flex-start;
	.std-page & {
		margin-bottom: 211px;
	}

	&__container {

	}
	&__wrap {
		margin-bottom: 44px;
		&:last-child {
			margin-bottom: 0;
		}
		&:only-child {
			margin-bottom: 0;
		}
	}
	&__title {
		color: #1b1923;
		.hammer();
		font-size: @36;
		margin-bottom: 26px;
	}
	&__text-wrap {

	}
	&__text {
		color: #1b1923;
		font-size: @16;
		.opensans();
		line-height: 1.4;
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 1030px) {
	.text-block {
		margin-bottom: 70px;
		.container_special & {
			padding-left: 15px;
			padding-right: 15px;
		}
		.std-page & {
			margin-bottom: 70px;
		}

		&__title {
			margin-bottom: 11px;
		}
		&__text {
			line-height: 1.5;
		}
	}
}

@media screen and (max-width: 980px) {
	.text-block {
		flex-direction: column;


		&__container {
			margin-bottom: 30px;
		}
	}
}

@media screen and (max-width: 700px) {
	.text-block {
		margin-bottom: 56px;

		.std-page & {
			margin-bottom: 56px;
		}
	}
}
