.header {
	padding-top: 20px;
	margin-bottom: 65px;
	position: relative;
	z-index: 40;
	&__container {

	}
	&__wrap {

	}
	&__top-line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	&__logo {
		background: url(../img/logo.png) no-repeat center center;
		background-size: contain;
		width: 187px;
		height: 86px;
		text-decoration: none;
		//margin-right: 54px;
	}

	&__right-part {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__phone {
		height: 60px;
		border-radius: 4px;
		background: @green-color;
		text-decoration: none;
		padding: 0 22px 0 25px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: -23px;
		.std-transition();

		.no-touchevents & {
			&:hover {
				background: @yellow-color;
			}
		}
		.header_pink & {
			background: @pink-color;

			.no-touchevents & {
				&:hover {
					background: @blue-color;
				}
			}
		}
		.header_red & {
			background: @red-color;

			.no-touchevents & {
				&:hover {
					background: @yellow-color;
				}
			}
		}
		.header_yellow & {
			background: @yellow-color;

			.no-touchevents & {
				&:hover {
					background: @green-color;
				}
			}
		}
		.header_blue & {
			background: @blue-color;

			.no-touchevents & {
				&:hover {
					background: @green-color;
				}
			}
		}
	}
	&__phone-icon {
		.svg-icon(icon-phone, 13px, 16px);
		margin-right: 10px;
		text-decoration: none;
	}
	&__phone-text {
		color: #ffffff;
		font-size: @24;
		.hammer();
		text-decoration: none;
	}

	&__burger {
		.svg-icon(icon-burger, 24px, 24px);
		text-decoration: none;
		display: none;
		.header_pink & {
			.svg-icon-param(icon-burger, 24px, 24px, '[fill]: #d70094');
		}
		.header_red & {
			.svg-icon-param(icon-burger, 24px, 24px, '[fill]: #ea1212');
		}
		.header_yellow & {
			.svg-icon-param(icon-burger, 24px, 24px, '[fill]: #fbb401');
		}
		.header_blue & {
			.svg-icon-param(icon-burger, 24px, 24px, '[fill]: #37cdfc');
		}
	}

	&__nav-wrap {
		margin-left: 187px + 54px;
		margin-right: 236px + 29px;
		margin-top: -65px;
	}
	&__nav {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__nav-item {
		margin-right: 23px;
		&:last-child {
			margin-right: 0;
		}
		&_with-dropdown {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
		}
	}
	&__nav-item-link {
		color: #1b1923;
		font-size: @17;
		.hammer();
		text-decoration: none;
		.std-transition();
		text-transform: lowercase;
		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__nav-item-icon {
		.svg-icon(icon-menu-caret, 10px, 6px);
		margin-left: 3px;
	}

	&__nav-item-dropdown {
		position: absolute;
		left: -10px;
		top: -10px;
		background: @green-color;
		display: flex;
		flex-direction: column;
		padding: 10px 20px;
		display: none;
		.header_pink & {
			background: @pink-color;
		}
		.header_red & {
			background: @red-color;
		}
		.header_yellow & {
			background: @yellow-color;
		}
		.header_blue & {
			background: @blue-color;
		}
	}
	&__nav-item-dropdown-item {
		color: #ffffff;
		font-size: @18;
		.hammer();
		text-decoration: none;
		white-space: nowrap;
		margin-bottom: 10px;
		display: block;
		&:last-child {
			margin-bottom: 0;
		}
		.no-touchevents & {
			&:hover {

			}
		}
	}

}

@media screen and (max-width: 1200px) {
	.header {
		&__nav-wrap {
			margin-left: 187px;
			margin-right: 236px;
			margin-top: -65px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__nav-item {
			margin-right: 15px;
		}
	}
}

@media screen and (max-width: 1090px) {
	.header {
		&__logo {
			width: 140px;
			height: 64px;
		}

		&__phone {
			height: 48px;
			padding: 0 10px;
		}
		&__phone-text {
			font-size: @18;
		}


		&__nav-wrap {
			margin-left: 140px + 10px;
			margin-right: 168px + 10px;
			text-align: center;
			margin-top: -50px;
		}
	}
}

@media screen and (max-width: 940px) {
	.header {
		margin-bottom: 0;
		&__top-line {
			margin-bottom: 10px;
		}
		&__nav-wrap {
			margin-top: 0;
			margin-left: 0;
			margin-right: 0;
		}
		&__nav-item {
			margin-right: 23px;
		}
	}
}

@media screen and (max-width: 700px) {
	.header {
		padding-top: 17px;

		&__top-line {
			margin-bottom: 0;
		}
		&__logo {
			width: 99px;
			height: 46px;
		}
		&__phone {
			height: auto;
			border-radius: 0;
			background: transparent;
			padding: 0;
			margin-top: 0;
			margin-right: 24px;
			.header_pink & {
				background: transparent;
			}
			.header_red & {
				background: transparent;
			}
			.header_yellow & {
				background: transparent;
			}
			.header_blue & {
				background: transparent;
			}
		}
		&__phone-icon {
			.svg-icon-param(icon-phone, 20px, 24px, '[fill]: #99c600');
			margin-right: 0;
			.header_pink & {
				.svg-icon-param(icon-phone, 20px, 24px, '[fill]: #d70094');
			}
			.header_red & {
				.svg-icon-param(icon-phone, 20px, 24px, '[fill]: #ea1212');
			}
			.header_yellow & {
				.svg-icon-param(icon-phone, 20px, 24px, '[fill]: #fbb401');
			}
			.header_blue & {
				.svg-icon-param(icon-phone, 20px, 24px, '[fill]: #37cdfc');
			}
		}
		&__phone-text {
			display: none;
		}
		&__burger {
			display: block;
		}
		&__nav-wrap {
			display: none;
		}
	}
}