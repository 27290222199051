// RESET CSS
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 16px;
	font: inherit;
}

input, textarea {
	outline: none;
}

textarea {
	resize: none;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	box-sizing: border-box;
}

.all-wrap {
	overflow: hidden;
}

//Standart container
.container {
	max-width: 1170px + 30px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

@media screen and (max-width: 1030px) {
	.container {

		&_special {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

//Background container 1920px
.bg-container {
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

body {
	min-width: 320px;
	background-color: white;
	text-align: left;
	position: relative;
	opacity: 0;
	transition: opacity .3s ease;
	.opensans();
	&.loaded {
		opacity: 1;
	}
}

.hidden-element {
	display: none;
}