.clients {
  margin-bottom: 130px;

  &__container {

  }
  &__title {
    text-align: center;
    margin-bottom: 19px;
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    width: calc(~"(100% - 17px * 3) / 4");
    margin-right: 17px;
    margin-bottom: 20px;
    border-radius: 16px;
    background-color: #f8f8f8;
    height: 220px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__item-img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 1030px) {
  .clients {

    &__item {
      width: calc(~"(100% - 17px ) / 2");
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .clients {

    &__list {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__item {
      width: 280px;
      margin-right: 0;
    }
  }
}
