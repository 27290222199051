.works-item {
	width: calc(~"(100% - 16px * 2) / 3");
	margin-right: 16px;
	margin-bottom: 16px;
	&:last-child {
		margin-right: 0;
		position: relative;
		.catalog_works & {
			&:after {
				content: '';
				position: absolute;
				top: 122px;
				right: -128px;
				width: 183px;
				height: 217px;
				background: url(../img/works-detail-1.png) no-repeat 0 0;
				background-size: contain;
				z-index: 30;
			}
		}
	}
	&:nth-child(3n) {
		margin-right: 0;
	}
	.portfolio__list & {
		margin-bottom: 42px;
	}

	&__img {
		width: 100%;
		height: 0;
		padding-bottom: 74.0740741%;
		position: relative;
		margin-bottom: 17px;
		display: block;
		border-radius: 4px;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(56, 54, 64, 0.5);
			opacity: 0;
			visibility: hidden;
			.std-transition();
		}
		.no-touchevents & {
			&:hover {
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&__img-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	&__name-wrap {

	}
	&__name {
		color: #1b1923;
		font-size: @24;
		line-height: 1.25;
		.hammer();
		text-decoration: none;
		.std-transition();
		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.works-item {
		width: calc(~"(100% - 14px) / 2");
		margin-right: 14px;
		margin-bottom: 16px;
		&:last-child {
			.catalog_works & {
				&:after {
					display: none;
				}
			}
		}
		&:nth-child(3n) {
			margin-right: 14px;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
		.catalog_works & {
			&:nth-child(3) {
				display: none;
			}
		}

		&__img {
			width: 100%;
			height: 0;
			padding-bottom: 74.0740741%;
			position: relative;
			margin-bottom: 17px;
			display: block;
			border-radius: 4px;
			overflow: hidden;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(56, 54, 64, 0.5);
				opacity: 0;
				visibility: hidden;
				.std-transition();
			}
			.no-touchevents & {
				&:hover {
					&:after {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		&__img-inner {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}

		&__name-wrap {

		}
		&__name {
			color: #1b1923;
			font-size: @24;
			line-height: 1.25;
			.hammer();
			text-decoration: none;
			.std-transition();
			.no-touchevents & {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.works-item {
		width: 290px;
		margin-right: 0;
		margin-bottom: 35px;
	}
}