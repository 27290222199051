.portfolio {
	position: relative;
	margin-top: 160px;
	margin-bottom: 152px;
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -970px;
		top: -130px;
		background: url(../img/yellow-bg.png) no-repeat center center;
		background-size: contain;
		width: 1920px;
		height: 862px;
	}

	&__container {
		position: relative;
		z-index: 20;
	}

	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 47px;
	}
	&__title {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 844px;
			height: 250px;
			background: url(../img/yellow-detail-1.png) no-repeat center center;
			background-size: contain;
			left: -122px;
			top: -104px;
			z-index: 30;
		}
	}

	&__filters {
		background: @grey-color;
		padding: 24px 70px;
		margin-bottom: 16px;
		border-radius: 16px;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		&_response {
			display: none;
		}
	}
	&__filters-item {
		color: #ffffff;
		.hammer();
		font-size: @16;

		height: 42px;
		line-height: 42px;
		text-decoration: none;
		padding: 0 16px;
		border: 2px solid transparent;
		border-radius: 4px;
		margin-bottom: 4px;
		.std-transition();
		margin-right: 4px;
		&_active {
			border-color: @yellow-color;
		}
		&:hover {
			border-color: @yellow-color;
		}
	}
	&__filters-select-holder {
		width: 100%;
	}
	&__filters-select {
		width: 100%;
	}

	&__list-wrap {

	}
	&__list-container {
		display: none;
		&_active {
			display: block;
		}
	}
	&__list {

		.alm-reveal  {
			display: flex;
			flex-wrap: wrap;
		}
	}
	&__button-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 1030px) {
	.portfolio {
		margin-top: 100px;
		&:after {
			margin-left: -480px;
			top: -10px;
			width: 960px;
			height: 431px;
		}

		&__title {
			&:after {
				width: 422px;
				height: 125px;
				left: -62px;
				top: -14px;
			}
		}

		&__filters {
			padding: 24px 30px;
		}
	}
}

@media screen and (max-width: 700px) {
	.portfolio {
		margin-top: 40px;

		&__filters {
			display: none;
			&_response {
				display: block;
				width: 290px;
				margin-left: auto;
				margin-right: auto;
				background: transparent;
				z-index: 40;
				position: relative;
				padding: 0;
			}
		}
		&__list {
			flex-direction: column;
			align-items: center;
			flex-wrap: nowrap;
		}
	}
}