.insta {
  margin-bottom: 112px;

  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
  &__list {
    margin-bottom: 60px;
  }
  &__button-wrap {
    display: flex;
    justify-content: center;
  }
}
