.testimonials {
  position: relative;
  z-index: 20;
  margin-bottom: 100px;
  &__container {

  }
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
  &__main {
    display: flex;
  }
  &__content-wrap {
    width: calc(~"(100% - 20px) / 2");
    margin-right: 20px;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 24px 30px 46px 30px;
  }
  &__content-item {
    display: none;
    &_active {
      display: block;
    }
  }
  &__content-item-title {
    font-size: 24px;
    color: #1b1923;
    .hammer();
    margin-bottom: 5px;
  }
  &__content-item-text {
    margin-bottom: 29px;
  }
  &__content-item-bottom {
    display: flex;
    align-items: center;
  }
  &__content-item-name {
    color: #1b1923;
    font-size: 16px;
    .opensans-bold();
    margin-right: 16px;
    &:before {
      content: '-';
      color: #1b1923;
      font-size: 16px;
      .opensans-bold();
      display: inline-block;
      margin-right: 5px;
    }
  }
  &__content-item-logo-wrap {
    width: 77px;
  }
  &__content-item-logo {
    width: 100%;
  }

  &__clients-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(~"(100% - 20px) / 2");
  }
  &__clients-item {
    width: calc(~"(100% - 17px) / 2");
    margin-right: 17px;
    border-radius: 16px;
    background-color: #f8f8f8;
    height: 220px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f8f8f8;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &_active {
      border-color: #8fbe05;
    }
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }
  &__clients-item-img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 980px) {
  .testimonials {

    &__main {
      flex-direction: column-reverse;
    }
    &__content-wrap {
      width: 100%;
      margin-right: 0;
    }

    &__clients-list {
      width: 100%;
      margin-bottom: 20px;
    }
    &__clients-item {
      width: calc(~"(100% - 17px * 3) / 4");
      height: 150px;
      &:nth-child(2n) {
        margin-right: 17px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-last-child(-n+4) {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .testimonials {

    &__clients-item {
      width: calc(~"(100% - 17px) / 2");
      height: 100px;
      &:nth-child(4n) {
        margin-right: 17px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(-n+4) {
        margin-bottom: 20px;
      }
      &:nth-last-child(-n+2) {
        margin-bottom: 20px;
      }
    }
  }
}
