.other-products {
	margin-bottom: 210px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		background: url(../img/other-products-bg.png) no-repeat center center;
		background-size: contain;
		width: 1731px;
		height: 672px;
		left: 50%;
		margin-left: -865px;
		bottom: -49px;
		z-index: 10;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
	}
	&__title {

	}
	&__list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (max-width: 1030px) {
	.other-products {
		margin-bottom: 105px;
		/*&:after {
			bottom: auto;
			top: -230px;
		}*/
		&:after {
			width: 865px;
			height: 313px;
			margin-left: -432px;
			bottom: auto;
			top: -50px;
		}


		&__list {
			flex-wrap: wrap;
		}
	}
}

@media screen and (max-width: 700px) {
	.other-products {

		&__list {
			flex-wrap: nowrap;
			flex-direction: column;
		}
	}
}