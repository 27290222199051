.downloads {
	margin-bottom: 120px;
	margin-top: 161px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		width: 1701px;
		height: 689px;
		background: url(../img/downloads-bg.png) no-repeat top center;
		background-size: contain;
		top: -116px;
		left: 50%;
		margin-left: -741px;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 35px;
	}
	&__title {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 129px;
			height: 111px;
			background: url(../img/downloads-detail.png) no-repeat top center;
			background-size: contain;
			right: -129px - 47px;
			top: -45px;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&__item {
		width: calc(~"(100% - 16px * 3) / 4");
		margin-right: 16px;
		border-radius: 16px;
		background-color: @grey-color;
		height: 160px;
		margin-bottom: 16px;
		text-decoration: none;
		padding: 36px 20px 30px 20px;
		.std-transition();

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		&:nth-child(4n) {
			margin-right: 0;
		}
		.no-touchevents & {
			&:hover {
				background-color: #383640;
			}
		}
	}
	&__item-name {
		color: #ffffff;
		font-size: @24;
		line-height: 1.25;
		.hammer();
		text-align: center;
		text-decoration: none;
		.std-transition();
		.downloads__item:hover & {
			.no-touchevents & {
				text-decoration: underline;
			}
		}
	}
	&__item-info {
		color: rgba(255, 255, 255, 0.5);
		font-size: @18;
		.hammer();
	}
}

@media screen and (max-width: 1030px) {
	.downloads {
		margin-top: 100px;
		//&:after {
		//	top: -137px;
		//	margin-left: -631px;
		//}
		&:after {
			width: 850px;
			height: 344px;
			top: 0;
			margin-left: -425px;
		}

		&__title {
		/*	&:after {
				right: -185px;
				top: -10px;
			}*/
		}
		&__title {
			&:after {
				width: 64px;
				height: 55px;
				right: -127px;
				top: 50px;
			}
		}

		&__item {
			width: calc(~"(100% - 15px * 2) / 3");
			margin-right: 15px;
			&:nth-child(4n) {
				margin-right: 15px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		&__item-name {
			font-size: @18;
			line-height: 1.67;
		}
	}
}

@media screen and (max-width: 700px) {
	.downloads {
		margin-top: 40px;
	/*	&:after {
			top: -187px;
			margin-left: -394px;
		}*/
		&:after {
			top: 60px;
			margin-left: -195px;
		}
		&__title-wrap {
			margin-bottom: 30px;
		}
		&__title {
			/*&:after {
				right: -124px;
				top: -20px;
			}*/
			&:after {
				right: -67px;
				top: 30px;
			}
		}

		&__list {
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
		}
		&__item {
			padding: 30px 20px 30px 20px;
			width: 290px;
			margin-right: 0;
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
		&__item-name {
			font-size: @24;
		}
	}
}
