.get-a-quote {
  width: 413px;
  min-width: 413px;
  border-radius: 10px;
  background-color: #282730;
  padding: 26px 26px 34px 34px;
  position: relative;
  margin-left: 70px;

  &_main {
    margin-top: -170px;
  }

  &__title {
    font-size: @36;
    letter-spacing: 1.27px;
    text-transform: uppercase;
    color: #8fbe05;
    margin-bottom: 33px;
    .lato();
  }
}

@media screen and (max-width: 980px) {
  .get-a-quote {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    &_main {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .get-a-quote {
    padding: 26px 14px 26px 14px;


    &__title {
      font-size: @30;
    }
  }
}

@media screen and (max-width: 500px) {
  .get-a-quote {

    &__title {
      font-size: @24;
    }
  }
}
