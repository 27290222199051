.std-top-block {
	margin-top: 160px;

	&_signs {
		margin-bottom: 183px - 16px;
	}
	&_illuminated {
		margin-bottom: 142px;
	}
	&_cnc {
		margin-bottom: 142px;
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		margin-bottom: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__title {
		position: relative;
		.std-top-block_signs & {
			&:after {
				content: '';
				position: absolute;
				background: url(../img/signs-detail.png) no-repeat 0 0;
				background-size: contain;
				width: 334px;
				height: 162px;
				left: 50%;
				margin-left: -194px;
				top: -70px;
				z-index: 10;
			}
		}
		.std-top-block_illuminated & {
			&:after {
				content: '';
				position: absolute;
				background: url(../img/illuminated-detail-3.png) no-repeat 0 0;
				background-size: contain;
				width: 637px;
				height: 175px;
				left: 50%;
				margin-left: -267px;
				top: -79px;
				z-index: 10;
			}
		}
		.std-top-block_cnc & {
			&:after {
				content: '';
				position: absolute;
				background: url(../img/cnc-detail.png) no-repeat 0 0;
				background-size: contain;
				width: 1219px;
				height: 159px;
				left: -110px;
				top: -50px;
				z-index: 10;
			}
		}
	}

	&__text-block {
		margin-bottom: 44px;
	}
	&__text {
		color: #1b1923;
		font-size: @16;
		.opensans();
		line-height: 1.5;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__list-wrap {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			background: url(../img/signs-bg.png) no-repeat 0 0;
			background-size: contain;
			width: 1920px;
			height: 592px;
			left: 50%;
			margin-left: -960px;
			top: -303px;
			z-index: 10;
		}
		.std-top-block_illuminated & {
			&:after {
				margin-left: -965px;
				top: -285px;
			}
		}
	}
	&__list {
		z-index: 20;
		position: relative;
		.std-top-block_signs & {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}
}

@media screen and (max-width: 1030px) {
	.std-top-block {
		margin-top: 100px;

		&__title {
			.std-top-block_signs & {
				&:after {
					width: 169px;
					height: 81px;
					margin-left: -120px;
					top: -10px;
				}
			}
			.std-top-block_illuminated & {
				&:after {
					width: 318px;
					height: 87px;
					margin-left: -159px;
					top: -49px;
					transform: rotate(-10deg);
				}
			}
			.std-top-block_cnc & {
				&:after {
					width: 609px;
					height: 79px;
					left: -70px;
					top: -10px;
				}
			}
		}

		&__list-wrap {
			&:after {
				width: 1030px;
				height: 350px;
				left: 50%;
				margin-left: -515px;
				top: -113px;
			}
			.std-top-block_illuminated & {
				&:after {
					width: 1030px;
					height: 350px;
					left: 50%;
					margin-left: -515px;
					top: -113px;
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.std-top-block {
		margin-top: 40px;

		&__list {
			.std-top-block_signs & {
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
			}
		}
	}
}
