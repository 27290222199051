.wide-item {
	width: 100%;
	margin-bottom: 16px;
	border-radius: 16px;
	background: @grey-color;
	padding: 53px 58px;

	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	&:last-child {
		margin-bottom: 0;
	}
	&:nth-child(1) {
		&:after {
			content: '';
			position: absolute;
			right: -36px;
			bottom: -75px;
			background: url(../img/illuminated-detail-1.png) no-repeat 0 0;
			background-size: contain;
			width: 219px;
			height: 167px;
			z-index: 30;
		}
	}
	&:nth-child(4) {
		&:after {
			content: '';
			position: absolute;
			left: -81px;
			top: -51px;
			background: url(../img/illuminated-detail-2.png) no-repeat 0 0;
			background-size: contain;
			width: 183px;
			height: 217px;
			z-index: 30;
		}
	}

	&__img {
		width: 165px;
		min-width: 165px;
		margin-right: 55px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__text-wrap {
		width: calc(~"100% - 165px - 55px");
	}
	&__title {
		color: #ffffff;
		font-size: @24;
		.hammer();
		line-height: 1.25;
		margin-bottom: 7px;
	}
	&__desc {
		color: #ffffff;
		font-size: @16;
		line-height: 1.5;
		.opensans();
		margin-bottom: 20px;
	}
	&__button-wrap {

	}
	&__button {

	}

}

@media screen and (max-width: 1030px) {
	.wide-item {
		padding: 53px 58px 53px 33px;
		align-items: flex-start;
		&:nth-child(1) {
			&:after {
				right: -6px;
				bottom: 5px;
				width: 109px;
				height: 83px;
			}
		}
		&:nth-child(4) {
			&:after {
				left: 0;
				width: 91px;
				height: 108px;
			}
		}

		&__img {
			width: 135px;
			min-width: 135px;
			margin-right: 15px;
		}
		&__text-wrap {
			width: calc(~"100% - 135px - 15px");
		}
	}
}

@media screen and (max-width: 700px) {
	.wide-item {
		flex-direction: column;
		padding: 53px 25px 53px 25px;

		&__img {
			width: 165px;
			min-width: 165px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
		&__text-wrap {
			width: 100%;
		}
		&__title {
			text-align: center;
		}
		&__desc {
			text-align: center;
		}
	}
}