.btn {
	display: inline-block;
	height: 48px;
	line-height: 48px;
	border: 0;
	cursor: pointer;
	position: relative;
	border-radius: 4px;

	color: white;
	width: 240px;
	font-size: @18;
	.hammer();
	text-align: center;

	text-decoration: none;
	.std-transition();
	outline: none;
	-webkit-appearance: none;

	&_block {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
	&_red {
		background: @red-color;

		.no-touchevents & {
			&:hover {
				background: @yellow-color;
			}
		}
	}
	&_grey {
		background: @grey-color;

		.no-touchevents & {
			&:hover {
				background: @yellow-color;
			}
		}
	}
	&_blue {
		background: @blue-color;

		.no-touchevents & {
			&:hover {
				background: @yellow-color;
			}
		}
	}
	&_pink {
		background: @pink-color;

		.no-touchevents & {
			&:hover {
				background: @blue-color;
			}
		}
	}
	&_yellow {
		background: @yellow-color;

		.no-touchevents & {
			&:hover {
				background: @green-color;
			}
		}
	}
	&_green {
		background: @green-color;

		.no-touchevents & {
			&:hover {
				background: @blue-color;
			}
		}
	}
	&_red-border {
		border: 2px solid @red-color;
		.no-touchevents & {
			&:hover {
				background: @red-color;
			}
		}
	}

	&_green-border {
		border: 2px solid @green-color;
		.no-touchevents & {
			&:hover {
				background: @green-color;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.btn {

		.cta & {
			width: 100%;
		}
		.wide-item & {
			width: 100%;
		}
		.form__button-holder & {
			width: 100%;
		}
	}
}


