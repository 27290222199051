.comments {
	width: 100%;

	&__title {
		color: #1b1923;
		font-size: @36;
		margin-bottom: 34px;
		.hammer();
	}

	&__list {
		margin-bottom: 35px;
	}
	&__item {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 36px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__item-img {
		width: 72px;
		min-width: 72px;
		height: 72px;
		border-radius: 4px;
		margin-right: 19px;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	&__item-text-wrap {
		width: calc(~"100% - 72px - 19px");
	}
	&__item-name {
		color: #1b1923;
		font-size: @24;
		line-height: 1;
		.hammer();
		margin-bottom: 8px;
	}
	&__item-text {
		color: #1b1923;
		.opensans();
		line-height: 1.5;
		margin-bottom: 15px;
		font-size: @16;
	}
	&__item-info {
		font-size: @14;
		color: rgba(27, 25, 35, 0.5);
		.opensans();
	}
}

@media screen and (max-width: 1030px) {
	.comments {
		&__title {
			margin-bottom: 30px;
		}

		&__list {
			margin-bottom: 35px;
		}
		&__item {
			margin-bottom: 25px;
		}
		&__item-text {
			margin-bottom: 10px;
		}
	}
}

@media screen and (max-width: 700px) {
	.comments {
		&__item-img {
			width: 48px;
			min-width: 48px;
			height: 48px;
			margin-right: 17px;
		}
		&__item-text-wrap {
			width: calc(~"100% - 48px - 17px");
		}
	}
}