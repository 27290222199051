.our-products {
	position: relative;
	margin-bottom: 156px;

	&:after {
		content: '';
		position: absolute;
		bottom: -49px;
		left: 50%;
		margin-left: -834px;
		width: 1731px;
		height: 672px;
		background: url(../img/products-bg.png) no-repeat center bottom;
		background-size: contain;
		z-index: 10;
	}
	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
	}
	&__title {
		text-align: center;
	}
	&__list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	&__item {
		height: 280px;
		border-radius: 16px;
		background: @grey-color;
		text-decoration: none;
		width: calc(~"(100% - 16px * 3) / 4");
		margin-right: 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: relative;
		.std-transition();
		&:last-child {
			margin-right: 0;
			&:after {
				content: '';
				position: absolute;
				width: 129px;
				height: 111px;
				background: url(../img/products-detail.png) no-repeat 0 0;
				background-size: contain;
				bottom: 56px;
				right: -89px;
				z-index: 30;
			}
		}
		.no-touchevents & {
			&:hover {
				background-color: #383640;
			}
		}
	}
	&__item-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 50%;
	}
	&__item-icon {
		width: 81px;
		height: 79px;
		position: relative;
		margin-bottom: 22px;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
		}
		&_signage {
			&:after {
				.svg-icon(icon-prod-signage, 77px, 79px);
				margin-left: -38px;
			}
		}
		&_illuminated {
			&:after {
				.svg-icon(icon-prod-illuminated, 57px, 79px);
				margin-left: -28px;
			}
		}
		&_digital {
			&:after {
				.svg-icon(icon-prod-digital, 81px, 79px);
				margin-left: -40px;
			}
		}
		&_cnc {
			&:after {
				.svg-icon(icon-prod-cnc, 48px, 79px);
				margin-left: -24px;
			}
		}
	}
	&__item-text {
		color: #ffffff;
		font-size: @24;
		.hammer();
		text-align: center;
		.std-transition();
		.our-products__item:hover & {
			.no-touchevents & {
				text-decoration: underline;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.our-products {
		&:after {
			top: -179px;
			margin-left: -739px;
		}

		&__title {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 80px;
				height: 95px;
				background: url(../img/blue-detail-3.png) no-repeat 0 0;
				background-size: contain;
				top: -72px;
				right: -74px;
				z-index: 30;
			}
		}
		&__list {
			flex-wrap: wrap;
		}
		&__item {
			width: calc(~"(100% - 14px) / 2");
			margin-bottom: 16px;
			margin-right: 14px;
			height: 220px;
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
				&:after {
					width: 129px;
					height: 111px;
					background: url(../img/blue-detail-4.png) no-repeat 0 0;
					background-size: contain;
					top: -41px;
					right: auto;
					left: -80px;
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.our-products {
		&:after {
			top: -197px;
		}
		&__title-wrap {
			margin-bottom: 22px;
		}

		&__title {
			&:after {
				top: -82px;
				right: -50px;
			}
		}
		&__list {
			flex-wrap: nowrap;
			flex-direction: column;
		}
		&__item {
			width: 290px;
			height: 220px;
			margin-right: 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}