.contacts {
	margin-top: 160px;
	margin-bottom: 145px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: -158px;
		//left: 50%;
		//margin-left: -965px;
		right: -10px;
		background: url(../img/blue-bg.png) no-repeat center center;
		background-size: contain;
		width: 1920px;
		height: 867px;
		z-index: 10;
	}

	&__container {
		position: relative;
		z-index: 20;
	}

	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
	}
	&__title {

	}

	&__map-wrap {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: -83px;
			right: 252px;
			background: url(../img/blue-detail-1.png) no-repeat center center;
			background-size: contain;
			width: 80px;
			height: 95px;
			z-index: 30;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 94px;
			left: -104px;
			background: url(../img/blue-detail-2.png) no-repeat center center;
			background-size: contain;
			width: 129px;
			height: 111px;
			z-index: 30;
		}
	}
	&__map {
		height: 480px;
		width: 100%;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		margin-bottom: 53px;
		-webkit-appearance: none;
		-webkit-transform: translate3d(0px, 0px, 0px);
		-webkit-mask-image: -webkit-radial-gradient(white, black);

	}
	&__map-section {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 16px;
		overflow: hidden;
		-webkit-appearance: none;
		-webkit-transform: translate3d(0px, 0px, 0px);
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	}
	&__map-itself {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		overflow: hidden;
		-webkit-appearance: none;
		-webkit-transform: translate3d(0px, 0px, 0px);
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	}

	&__info {

	}
	&__info-line {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 50px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__info-block {
		margin-right: 104px;
		&:only-child {
			margin-right: 0;
		}
	}
	&__info-text {
		color: rgba(27, 25, 35, 0.5);
		font-size: @14;
		.opensans();
		margin-bottom: 15px;
	}
	&__info-phone {
		color: #1b1923;
		font-size: @42;
		.hammer();
		a {
			color: #1b1923;
			text-decoration: none;
		}
	}
	&__info-mail {
		color: #1b1923;
		font-size: @42;
		.hammer();
		text-decoration: none;
		.std-transition();
		.no-touchevents & {
			&:hover {
				color: @blue-color;
			}
		}
	}
	&__info-address {
		color: #1b1923;
		font-size: @42;
		.hammer();
	}
}

@media screen and (max-width: 1030px) {
	.contacts {
		margin-top: 100px;
		margin-bottom: 145px;
		position: relative;
		&:after {
			top: -78px;
			right: 0;
			width: 960px;
			height: 435px;
		}

		&__map-wrap {
			position: relative;
		/*	&:before {
				right: 32px;
			}
			&:after {
				bottom: -24px;
				left: -50px;
			}*/
			&:before {
				top: -22px;
				right: 252px;
				width: 40px;
				height: 47px;
			}
			&:after {
				bottom: 34px;
				left: -34px;
				width: 64px;
				height: 55px;
			}
		}

		&__info-block {
			margin-right: 15px;
			width: calc(~"35% - 15px");
			min-width: calc(~"35% - 15px");
			&:last-child {
				width: 65%;
				min-width: 65%;
			}
			&:only-child {
				width: 100%;
				margin-right: 0;
			}
		}

		&__info-phone {
			font-size: @30;
		}
		&__info-mail {
			font-size: @30;
		}
		&__info-address {
			font-size: @30;
		}
	}
}

@media screen and (max-width: 700px) {
	.contacts {
		margin-top: 40px;
		&:after {
			top: 32px;
		}

		&__info-line {
			flex-direction: column;
			margin-bottom: 30px;
		}
		&__info-block {
			margin-right: 0;
			width: 100%;
			min-width: auto;
			&:last-child {
				width: 100%;
				min-width: auto;
			}
		}

		&__info-phone {
			font-size: @18;
		}
		&__info-mail {
			font-size: @18;
		}
		&__info-address {
			font-size: @18;
		}
	}
}