// Ajax load more
.alm-btn-wrap {
	display: flex;
	justify-content: center;

	.alm-load-more-btn {
		&:extend(.btn); // get the styles from .btn
		&.done {
			display: none;
		}
	}
}

// Blog
.catalog_blog .alm-load-more-btn {
	background: @yellow-color;
}

.no-touchevents .catalog_blog .alm-load-more-btn:hover {
	background: @green-color;
}

// Works
.catalog_works .alm-load-more-btn {
	background: @red-color;
}

.no-touchevents .catalog_works .alm-load-more-btn:hover {
	background: @yellow-color;
}

// Portfolio
.portfolio .alm-load-more-btn {
	background: @yellow-color;
}

.no-touchevents .portfolio .alm-load-more-btn:hover {
	background: @green-color;
}