.breadcrumbs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 17px;
	position: relative;
	z-index: 30;

	&__item {
		color: rgba(27, 25, 35, 0.5);
		font-size: @16;
		.opensans();
		text-decoration: none;
		position: relative;
		margin-right: 28px;
		&:after {
			content: '>';
			position: absolute;
			right: -20px;
			top: 0;
			color: rgba(27, 25, 35, 0.5);
			font-size: @16;
			.opensans();
		}
		&:last-child {
			margin-right: 0;
			&:after {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.breadcrumbs {
		margin-bottom: 30px;
		flex-wrap: wrap;
	}
}