.blog {
	margin-bottom: 99px;
	position: relative;
	margin-top: 160px;
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -970px;
		top: -130px;
		background: url(../img/yellow-bg.png) no-repeat center center;
		background-size: contain;
		width: 1920px;
		height: 862px;
	}
	&_search-results {
		&:after {
			top: -64px;
			margin-left: -1074px;
		}
	}
	&_inner {
		&:after {
			content: '';
			position: absolute;
			left: 50%;
			margin-left: -840px;
			top: 170px;
			background: url(../img/green-bg.png) no-repeat center center;
			background-size: contain;
			width: 1731px;
			height: 672px;
		}
	}

	&__container {
		position: relative;
		z-index: 20;
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 89px;
		.blog_inner & {
			margin-bottom: 50px;
		}
		.blog_search-results & {
			margin-bottom: 38px;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			z-index: 100;
		}
	}
	&__title {
		position: relative;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			width: 195px;
			height: 146px;
			background: url(../img/yellow-detail-2.png) no-repeat center center;
			background-size: contain;
			left: -198px;
			top: -43px;
			z-index: 30;
		}
		.blog_inner & {
			&:after {
				content: '';
				position: absolute;
				width: 80px;
				height: 95px;
				background: url(../img/green-detail-2.png) no-repeat center center;
				background-size: contain;
				left: -88px;
				top: -8px;
				z-index: 30;
			}
		}
	}
	&__wrap {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__main-part {
		width: calc(~"100% - 379px - 16px");
		margin-right: 16px;
	}
	&__list {
		.alm-reveal {
			display: flex;
			flex-wrap: wrap;
		}
	}
	&__button-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	&__article-wrap {
		margin-bottom: 83px;
	}

	&__filters {
		width: 379px;
		min-width: 379px;
		border-radius: 16px;
		background: @grey-color;
		padding: 39px 29px 52px 30px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 242px;
			height: 250px;
			background: url(../img/yellow-detail-3.png) no-repeat center center;
			background-size: contain;
			right: 144px;
			top: -208px;
			z-index: 30;
		}
		.blog_inner & {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 129px;
				height: 111px;
				background: url(../img/green-detail-3.png) no-repeat center center;
				background-size: contain;
				right: 64px;
				top: -83px;
				z-index: 30;
			}
		}
		.blog_search-results & {
			&:after {
				right: 134px;
			}
		}
	}

	&__filters-title {
		color: #ffffff;
		font-size: @30;
		.hammer();
		.blog__filters-categories & {
			margin-bottom: 13px;
		}
		.blog__filters-posts & {
			margin-bottom: 23px;
		}
		.blog__filters-archive & {
			margin-bottom: 35px;
		}
	}
	&__filters-search-block {
		margin-bottom: 24px;
	}
	&__filters-search-holder {
		width: 100%;
		position: relative;
	}
	&__filters-search {
		width: 100%;
		height: 48px;
		line-height: normal;
		border-radius: 4px;
		border: 2px solid #fbb401;
		background-color: #ffffff;
		padding: 0 48px 0 20px;
		outline: none;
		-webkit-appearance: none;
		color: #1b1923;
		font-size: @18;
		.hammer();

		&::-webkit-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-moz-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #1b1923;
			opacity: 1;
		}
		&:focus {

		}
		&_error {
			border-color: #ff0000;
		}
		.blog_inner & {
			border-color: @green-color;
		}
	}
	&__filters-search-trigger {
		position: absolute;
		right: 17px;
		top: 15px;
		.svg-icon(icon-search, 18px, 18px);
		border: 0;
		cursor: pointer;
		&:hover {
			.svg-icon-param(icon-search, 18px, 18px, '[fill]: #fbb401');
		}
	}

	&__filters-categories {
		margin-bottom: 40px;
	}
	&__filters-categories-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	&__filters-categories-item {
		color: #ffffff;
		font-size: @16;
		.hammer();
		text-decoration: none;
		height: 42px;
		line-height: 42px;
		border: 2px solid transparent;
		border-radius: 4px;
		padding: 0 15px;
		.std-transition();
		&_active {
			border-color: @yellow-color;
			.blog_inner & {
				border-color: @green-color;
			}
		}
		.no-touchevents & {
			&:hover {
				border-color: @yellow-color;
				.blog_inner & {
					border-color: @green-color;
				}
			}
		}
	}

	&__filters-posts {
		margin-bottom: 55px;
	}
	&__filters-posts-list {

	}
	&__filters-posts-item {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__filters-posts-item-img {
		width: 72px;
		min-width: 72px;
		height: 71px;
		margin-right: 19px;
		border-radius: 2px;
		overflow: hidden;
		text-decoration: none;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&__filters-posts-item-text-wrap {
		width: calc(~"100% - 72px - 19px");
	}
	&__filters-posts-item-title-wrap {
		margin-bottom: 1px;
	}
	&__filters-posts-item-title {
		color: #ffffff;
		font-size: @18;
		line-height: 1.33;
		.hammer();
		text-decoration: none;
		.std-transition();
		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__filters-posts-item-info {
		font-size: @14;
		line-height: 1.71;
		.opensans();
		color: rgba(255, 255, 255, 0.5);
	}

	&__filters-archive-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	&__filters-archive-item {
		color: #ffffff;
		font-size: @16;
		.hammer();
		text-decoration: none;
		.std-transition();
		margin-bottom: 30px;
		margin-right: 30px;
		&:last-child {
			margin-bottom: 0;
		}
		.no-touchevents & {
			&:hover {
				color: @yellow-color;
			}
		}
	}
}

@media screen and (max-width: 1030px) {
	.blog {
		margin-top: 100px;
		&:after {
			margin-left: -480px;
			top: 40px;
			width: 960px;
			height: 431px;
		}
		&_inner {
			&:after {
				margin-left: -432px;
				top: 20px;
				width: 865px;
				height: 336px;
			}
		}
		&_search-results {
			&:after {
				margin-left: -570px;
				top: 0;
			}
		}

		&__main-part {
			width: calc(~"100% - 251px - 16px");
			padding-left: 16px;
		}
		&__title {
			&:after {
				width: 97px;
				height: 73px;
				left: -88px;
				top: 7px;
			}
			.blog_inner & {
			/*	&:after {
					left: auto;
					right: -53px;
					top: -89px;
				}*/
				&:after {
					width: 40px;
					height: 47px;
					left: auto;
					right: -53px;
					top: -38px;
				}
			}
		}

		&__filters {
			width: 251px;
			min-width: 251px;
			border-radius: 16px 0 0 16px;
			padding: 20px 15px 52px 20px;
			&:after {
				width: 121px;
				height: 125px;
				right: 124px;
				top: -108px;
			}
			.blog_inner & {
				&:after {
					width: 64px;
					height: 55px;
					right: 4px;
					top: auto;
					bottom: -20px;
				}
			}
			.blog_search-results & {
				&:after {
					right: 14px;
					top: -108px;
				}
			}
		}

		&__filters-title {
			font-size: @24;
			margin-left: 10px;
			.blog__filters-categories & {
				margin-bottom: 11px;
			}
			.blog__filters-posts & {
				margin-bottom: 15px;
			}
			.blog__filters-archive & {
				margin-bottom: 16px;
			}
		}

		&__filters-search {
			padding: 0 48px 0 16px;
		}

		&__filters-categories {
			margin-bottom: 30px;
		}
		&__filters-categories-item {
			height: 36px;
			line-height: 36px;
			padding: 0 10px;
		}

		&__filters-posts {
			margin-bottom: 35px;
		}

		&__filters-posts-item {
			margin-left: 10px;
			margin-bottom: 20px;
		}
		&__filters-posts-item-img {
			width: 44px;
			min-width: 44px;
			height: 41px;
			margin-right: 13px;
		}
		&__filters-posts-item-text-wrap {
			width: calc(~"100% - 44px - 13px");
		}
		&__filters-posts-item-title-wrap {
			margin-top: -4px;
			margin-bottom: 0;
		}
		&__filters-posts-item-title {
			font-size: @13;
		}

		&__filters-archive-list {
			margin-left: 10px;
		}
		&__filters-archive-item {
			font-size: @14;
			margin-bottom: 20px;
			margin-right: 20px;
		}
	}
}

@media screen and (max-width: 700px) {
	.blog {
		margin-top: 40px;

		&__title-wrap {
			padding-left: 15px;
			padding-right: 15px;
			margin-bottom: 30px;
			.blog_inner & {
				margin-bottom: 30px;
			}
		}
		&__title {
			/*.blog_inner & {
				&:after {
					right: -6px;
					top: -20px;
				}
			}*/
		}
		&__wrap {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		&__main-part {
			width: calc(~"100% - 30px");
			margin-right: 15px;
			margin-left: 15px;
			margin-bottom: 40px;
			padding-left: 0;
		}

		&__filters {
			width: calc(~"100% - 30px");
			margin-right: 15px;
			margin-left: 15px;
			border-radius: 16px;
			&:after {
				display: none;
			}
			.blog_inner & {
				&:after {
					display: none;
				}
			}
			//.blog_search-results & {
			//	width: 100%;
			//	margin-left: 0;
			//	margin-right: auto;
			//}
		}
	}
}

@media screen and (max-width: 500px) {
	.blog {

		&__list {
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
		}
	}
}